.gradient_background {
    background: linear-gradient(90deg, #010101, #161616);
    min-height: 100vh;
    min-width: 100v;
}
.wrapper {
    display: flex;
    justify-content: center; 
    align-items: center; 
    min-height: 85vh;
    flex-direction: row; 
}
@media only screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column; 
        min-height:100vh;
    }
}
.center-content {
    text-align: center;
}
.imgbox {
    border: 2px solid #fffefe;
    width: 450px; 
    order: 1;
}
@media only screen and (max-width: 768px) {
    .imgbox {
        margin-top: 30%;
        order: unset; 
        width: 250px;
    }
}
.imgbox img {
    width: 100%;
    height: auto; 
    object-fit: fill;
    display: block;
}
.text-group {
    margin-left: 50px;
    padding-top: 1.4rem;
    font-family: 'MagnumSans', magnumsans;
    font-size: 24.6px;
    max-width: 700px;
    margin-bottom: 20px;
    color: #f3f1f1;
    position: relative;
    text-align: left;
    order: 2;
}
@media only screen and (max-width: 1068px){
    .text-group {
        font-family: 'Segoe UI';
        font-size: 14px;
        text-align: center;
        margin-left: auto;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 0px;
        order: 3;
    }
}
.secab {
    font-family: 'MagnumSans', magnumsans;
    text-align: center;
    color: white;
    font-size: 40px;
    padding-bottom: 50px;
}
@media only screen and (max-width: 1068px){
    .secab {
        font-size: 25px;
    }
}
@media only screen and (max-width: 768px){
    .secab {
        padding-top: 50px;
        font-size: 18px;
        padding-bottom: 80px;
    }
}
.gavin{
    margin-left: 1%;
    font-size: 18px;
    color: white;
    font-family: 'Segoe UI';
    font-size: 15px;
    text-decoration: underline;
}