@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@434&display=swap');
  
  .img-name {
    color: white;
    font-weight: normal;
    margin-top: 2px; 
    font-family: Outfit;
    font-size: 18px;
    max-width: 205px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    
  }
  @media (max-width: 1024px) {
    .img-name {
      margin-right: auto;
      margin-left: auto;
      padding-right: 17%;
      max-width: 300;
      overflow: hidden;
    }
  }

  
  .video-paper {
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
  
    &:hover {
      transform: scale(1.03);
    }
    .mobile-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
